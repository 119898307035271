.head {
  background: #ffffff;
  border: 1px solid rgba(23, 23, 23, 0.08);
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 24px;
    background: #ffffff;
    order: 4;

    .pollen_units_table_input {
      display: flex;
      align-items: center;
      border: 1px solid #a2a2a2;
      border-radius: 70px;
      width: 20%;
      input {
        font-size: 16px;
        width: 100%;
        border: 1px solid transparent;
        border-radius: 70px;
        padding: 13px 12px;
      }

      input:focus {
        outline: none;
        border: 1px solid transparent;
        background-color: #ffffff;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        margin-left: 13px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: 0.03em;
        color: #171717;
      }
      svg {
        margin-left: 15px;
      }
    }

    @media only screen and (max-width: 540px) {
      .pollen_units_table_input {
        display: flex;
        align-items: center;
        border: 1px solid #a2a2a2;
        border-radius: 70px;
        width: 40%;
        input {
          font-size: 16px;
          width: 100%;
          border: 1px solid transparent;
          border-radius: 70px;
          padding: 8px;
        }

        input:focus {
          outline: none;
          border: 1px solid transparent;
          background-color: #ffffff;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          margin-left: 13px;
          display: flex;
          align-items: center;
          text-align: right;
          letter-spacing: 0.03em;
          color: #171717;
        }
        svg {
          margin-left: 15px;
        }
      }
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      font-family: "General sans light";
      /* identical to box height, or 175% */

      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
      text-transform: capitalize;

      /* General/Black */

      color: #171717;
    }

    @media only screen and (max-width: 540px) {
      p {
        font-size: 12px;
      }
    }

    .form__inner__input {
      display: flex;
      flex-direction: column;
      border: 1px solid #a2a2a2;
      padding: 2px 4px;
      border-radius: 70px;
      width: 100%;
      justify-content: flex-end;
      select {
        font-size: 16px;
        border: 1px solid transparent;
        border-radius: 16px;
        padding: 13px 12px;
        background-color: white;
        color: black;
      }
      select:focus {
        outline: none;
      }
    }
    @media only screen and (max-width: 540px) {
      .form__inner__input {
        padding: 8px 4px;
        border-radius: 70px;
        select {
          font-size: 12px;
          border: 1px solid transparent;
          border-radius: 16px;
          padding: 8px;
          background-color: white;
          color: black;
        }
      }
      select:focus {
        outline: none;
      }
    }
  }
  .polling_units_table {
    margin: 48px 48px 0px 0px;
    background: #ffffff;
    border: 0.5px solid rgba(23, 23, 23, 0.1);
    box-shadow: 0px 2px 16px rgba(18, 33, 46, 0.04);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    .pollen_units_table_input {
      display: flex;
      align-items: center;
      margin: 24px;
      border: 1px solid #a2a2a2;
      border-radius: 70px;
      padding: 1px;
      width: 20%;
      input {
        font-size: 16px;
        width: 100%;
        border: 1px solid transparent;
        border-radius: 70px;
        padding: 13px 12px;
      }

      input:focus {
        outline: none;
        border: 1px solid transparent;
        background-color: #ffffff;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        margin-left: 13px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: 0.03em;
        color: #171717;
      }
      svg {
        margin-left: 15px;
      }
    }
    .pollen_units_table_input:nth-child(2) {
      width: 20%;
    }
  }
  .polling_units_table_cover {
    overflow-x: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;

    .polling_units_table_inner2 {
      @media only screen and (max-width: 540px) {
        div {
          padding: 14px 12px;
          p {
            font-size: 12px;
          }
        }
      }

      @media only screen and (max-width: 540px) {
      }
    }
  }
  @media only screen and (max-width: 920px) {
    .polling_units_table_cover {
      overflow-x: scroll;
      width: 100%;

      .polling_units_table_inner2 {
        background: #fcfcfc;
        width: 250%;
      }
    }
  }
}
