.polling_units_table_cover {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: scroll;
    font-family: 'General sans light';
}

.overlay {
    background-color: rgba(35, 35, 35, 0.984);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 50vh;
    z-index: 1000000;
    width: 100%;
    transition: all 1.5s ease-in-out;
}

.overlay .close_handler {
    margin-top: 100px;
    width: 30px;
    height: 30px;
    top: 0;
    right: 70px;
    position: absolute;
    cursor: pointer;
}

.line {
    position: absolute;
    top: 10%;
    right: 0;
    width: 100%;
    height: 2px;
    background-color: rgb(203, 203, 203);
    transform: translateY(-50%);
}

.line:first-child {
    transform: translateY(-50%) rotate(45deg);
}

.line:last-child {
    transform: translateY(-50%) rotate(-45deg);
}

.overlay_inner {
    color: rgb(203, 203, 203);
    margin: auto 150px;
}

.overlay_inner p {
    font-size: 26px;
    margin-bottom: 40px;
}

.overlay_inner p a {
    color: rgb(203, 203, 203);
}

.overlay_inner p a:hover {
    color: #21944b;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

.open {
    transition: all 1.5s ease-in-out;
}

.close {
    height: 0;
    transition: all 1.5s ease-in-out;
}

.polling_units_table_inner {
    display: flex;
    align-items: center;
    padding: 22px 24px;
    gap: 12px;
    background: #FCFCFC;
    border-bottom: 0.5px solid #EDEEF0;
    justify-content: space-between;
}

.polling_units_table_inner:nth-child(2),
.polling_units_table_inner:nth-child(4) {
    background-color: white;
}

.polling_units_table_inner div {
    display: flex;
    justify-content: flex-start;
}

.polling_units_table_inner div p {
    font-family: 'General Sans light';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #7A7A7B;

}

.polling_units_table_inner div:nth-child(1) {
    flex: 1;
    max-width: 25%;
    text-align: start;
    display: flex;
    justify-content: flex-start;
}

.polling_units_table_inner div:nth-child(2),
.polling_units_table_inner div:nth-child(3) {
    flex: 1;
    max-width: 10%;
    text-align: start;
    display: flex;
    justify-content: flex-start;
}

.polling_units_table_inner div:nth-child(4) {
    flex: 1;
    max-width: 20%;
    text-align: start;
    display: flex;
    justify-content: flex-start;
}

.polling_units_table_inner div:nth-child(5) {
    flex: 1;
    max-width: 20%;
    text-align: start;
    display: flex;
    justify-content: flex-start;
}

.polling_units_table_cover {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    overflow-x: scroll;
}

.polling_units_table_inner2 {
    display: flex;
    align-items: center;
    padding: 22px 24px;
    gap: 12px;
    background: #FCFCFC;
    border-bottom: 0.5px solid #EDEEF0;
    justify-content: space-between;
    text-align: center;
}

.polling_units_table_inner2 div:nth-child(1) {
    display: flex;
    flex: 1;
    max-width: 40%;
    text-align: start;
}

.polling_units_table_inner2 div {
    text-align: start;
    display: flex;
    justify-content: flex-start;
    /* word-break: break-all; */
}


.polling_units_table_inner2 div:nth-child(1) {
    flex: 1;
    max-width: 25%;
}

.polling_units_table_inner2 div:nth-child(2) {
    display: flex;
    justify-content: flex-start;
    flex: 1;
    max-width: 20%;
}

.polling_units_table_inner2 div:nth-child(3),
.polling_units_table_inner2 div:nth-child(4) {
    display: flex;
    justify-content: flex-start;
    flex: 1;
    max-width: 15%;

}

.polling_units_table_inner2 div:nth-child(5),
.polling_units_table_inner2 div:nth-child(6),
.polling_units_table_inner2 div:nth-child(7),
.polling_units_table_inner2 div:nth-child(8) {
    display: flex;
    justify-content: flex-start;
    flex: 1;
    max-width: 5%;
}

.polling_units_table_inner2 div p {
    font-family: 'General Sans light';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #7A7A7B;

}

.polling_units_table_inner2 div a {
    color: #7A7A7B;
}

.polling_units_table_inner div a {
    color: #7A7A7B;
}

.polling_units_table_inner3 div a {
    color: #7A7A7B;
}

.button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 24px;
    gap: 12px;
}

.button button {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 8px;
    color: #7A7A7B;
    background: #FFFFFF;
    border: 1px solid #C8D0D6;
    border-radius: 6px;
}

.imgWrapperInner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 70px;
}


.imgWrapperInner img {
    width: 40%;
    border-radius: 70px;
}


.polling_units_table_inner3 {
    display: flex;
    align-items: center;
    padding: 22px 24px;
    gap: 12px;
    background: #FCFCFC;
    border-bottom: 0.5px solid #EDEEF0;
    justify-content: space-between;
    /* text-align: center; */
}

.polling_units_table_inner3:nth-child(2),
.polling_units_table_inner3:nth-child(4) {
    background-color: white;
}

.polling_units_table_inner3 div {
    display: flex;
    justify-content: flex-start;
}

.polling_units_table_inner3 div p {
    font-family: 'General Sans light';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #7A7A7B;

}

.polling_units_table_inner3 div:nth-child(1) {
    flex: 1;
    max-width: 25%;
    text-align: start;
    display: flex;
    justify-content: flex-start;
}

.polling_units_table_inner3 div:nth-child(2),
.polling_units_table_inner3 div:nth-child(4) {
    flex: 1;
    max-width: 10%;
    text-align: start;
    display: flex;
    justify-content: flex-start;
}

.polling_units_table_inner3 div:nth-child(3) {
    flex: 1;
    max-width: 20%;
    text-align: start;
    display: flex;
    justify-content: flex-start;
}

.polling_units_table_inner3 div:nth-child(5) {
    flex: 1;
    max-width: 20%;
    text-align: start;
    display: flex;
    justify-content: flex-start;
}

@media only screen and (max-width: 920px) {
    /* .imgWrapperInner img {
        display: none;
        width: 0%;
    } */

    /* .imgWrapperInner {
        display: none;
    } */

    .polling_units_table_inner3 div a {
        color: #7A7A7B;
    }

    .polling_units_table_inner3 div a {
        color: #7A7A7B;
    }

    .polling_units_table_inner {
        width: 150%;
    }

    .polling_units_table_inner div:nth-child(1) {
        flex: 1;
        max-width: 35%;
        text-align: start;
        display: flex;
        justify-content: flex-start;
    }

    .polling_units_table_inner div:nth-child(2),
    .polling_units_table_inner div:nth-child(3) {
        flex: 1;
        max-width: 15%;
        text-align: start;
        display: flex;
        justify-content: flex-start;
    }

    .polling_units_table_inner div:nth-child(4) {
        flex: 1;
        max-width: 25%;
        text-align: start;
        display: flex;
        justify-content: flex-start;
    }

    .polling_units_table_inner div:nth-child(5) {
        flex: 1;
        max-width: 15%;
        text-align: start;
        display: flex;
        justify-content: flex-start;
    }

    .polling_units_table_inner div p {
        font-size: 12px;
    }

    .polling_units_table_inner2 {
        width: 200%;
    }

    .polling_units_table_inner2 div p {
        font-size: 12px;
    }

    .polling_units_table_inner2 div:nth-child(1) {
        flex: 1;
        max-width: 30%
    }

    .polling_units_table_inner2 div:nth-child(2) {
        flex: 1;
        max-width: 20%;
        font-size: 12px;
    }

    .polling_units_table_inner2 div:nth-child(3) {
        display: flex;
        justify-content: flex-start;
        flex: 1;
        max-width: 17%;

    }

    .polling_units_table_inner2 div:nth-child(4) {
        display: flex;
        justify-content: flex-start;
        flex: 1;
        max-width: 10%;

    }

    .polling_units_table_inner2 div:nth-child(5),
    .polling_units_table_inner2 div:nth-child(6),
    .polling_units_table_inner2 div:nth-child(7),
    .polling_units_table_inner2 div:nth-child(8) {
        display: flex;
        justify-content: flex-start;
        flex: 1;
        max-width: 5%;
    }

    .polling_units_table_inner div a {
        font-size: 12px;
    }

    .polling_units_table_inner2 div a {
        font-size: 12px;
    }

    .polling_units_table_inner3 div a {
        font-size: 12px;
    }


    .button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 18px;
        gap: 12px;
    }

    .button button {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        gap: 8px;
        color: #7A7A7B;
        background: #FFFFFF;
        border: 1px solid #C8D0D6;
        border-radius: 6px;
    }

    .button p {
        font-size: 12px;
    }


    .polling_units_table_inner3 {
        width: 200%;
    }

    .polling_units_table_inner3 div:nth-child(1) {
        flex: 1;
        max-width: 35%;
        text-align: start;
        display: flex;
        justify-content: flex-start;
    }

    .polling_units_table_inner3 div:nth-child(2),
    .polling_units_table_inner3 div:nth-child(4) {
        flex: 1;
        max-width: 15%;
        text-align: start;
        display: flex;
        justify-content: flex-start;
    }

    .polling_units_table_inner3 div:nth-child(3) {
        flex: 1;
        max-width: 25%;
        text-align: start;
        display: flex;
        justify-content: flex-start;
    }

    .polling_units_table_inner3 div:nth-child(5) {
        flex: 1;
        max-width: 15%;
        text-align: start;
        display: flex;
        justify-content: flex-start;
    }

    .polling_units_table_inner3 div p {
        font-size: 12px;
    }

    .overlay {
        overflow-y: scroll;
    }

    .overlay .close_handler {
        right: 10px;
        top: 0px;
        width: 20px;
        height: 20px;
        margin-top: 70px;
    }

    .line {
        position: absolute;
        top: 10%;
        right: 0;
        width: 100%;
        height: 2px;
        background-color: rgb(203, 203, 203);
        transform: translateY(-50%);
    }

    .overlay_inner {
        color: rgb(203, 203, 203);
        margin: auto 20px;
        height: 100%;
        margin-top: 150px;
    }

    .overlay_inner p {
        font-size: 16px;
        margin-bottom: 40px;
    }
}