.homepage {
  background-image: url("../assets/Rectangle\ 18876.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .homepage_header {
    .homepage_header_inner {
      margin: 18px 32px;
      display: flex;
      align-items: center;
    }
    .homepage_header_wrapper {
      width: 17%;
      margin-right: 8px;

      .homepage_header_wrapper_inner {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    p {
      font-family: "General Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      color: #171717;
    }
    @media only screen and (max-width: 540px) {
      .homepage_header_inner {
        display: flex;
        flex-direction: column;
        margin: 18px 16px;
      }
      p {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
      }
      .homepage_header_wrapper {
        align-items: center;
        justify-content: center;
        width: 70%;
        margin-right: 8px;
      }
    }
  }
  .homepage_body {
    margin: 135px auto;
    margin-bottom: 0px;
    .homepage_body_inner {
      width: 100%;
      flex-direction: column;
      display: flex;
      max-width: 699px;
      h1 {
        font-weight: 500;
        font-size: 56px;
        line-height: 76px;
        text-align: center;
        letter-spacing: -0.01em;
        text-transform: capitalize;

        color: #000000;

        span {
          text-decoration: underline;
          text-decoration-color: #1ed760;
          text-underline-offset: 0.2em;
        }
      }
      p {
        margin-top: 20px;
        font-style: normal;
        font-family: "General sans light";
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #171717;
      }
      .timer {
        margin: auto;
        margin-top: 40px;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px 24px;
          gap: 12px;
          width: 350px;
          background: #171717;
          text-decoration: none;
          border-radius: 92px;
          h6 {
            align-items: center;
            color: white;
            text-decoration: none;
            font-family: "General sans light";
            font-size: 18px;
          }
        }
      }
      @media only screen and (max-width: 920px) {
        .timer {
          padding: 0px 16px;

          a {
            width: 320px;
          }
        }
      }
      .checkback_info {
        margin-top: 187px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          vertical-align: middle;
        }

        @media only screen and (max-width: 920px) {
          margin-top: 107px;
        }

        @media only screen and (max-width: 540px) {
          svg {
            display: none;
          }
        }
        h6 {
          font-family: "General Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;

          text-align: center;
          letter-spacing: 0.1em;
          text-transform: uppercase;

          span {
            font-family: "General sans light";
          }
        }
      }
      .time {
        display: flex;
        margin-top: 24px;
        align-items: center;
        color: white;
        justify-content: center;
      }

      .time_inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px 24px;
        gap: 4px;

        width: 56px;
        // height: 60px;

        background: #171717;
        margin-right: 12px;
        opacity: 0.81;
        border-radius: 10px;

        h5 {
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 24px;
          /* identical to box height, or 109% */

          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.01em;

          /* General/White */

          color: #ffffff;
        }

        h5:nth-child(2) {
          font-family: "General Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 16px;
          /* identical to box height, or 145% */

          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: #fcfcfc;
        }
      }
    }

    @media only screen and (max-width: 540px) {
      .homepage_body_inner {
        max-width: 327px;

        h1 {
          font-family: "General Sans light";
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          line-height: 43px;
          text-align: center;
          letter-spacing: -0.01em;
          text-transform: capitalize;
          color: #000000;
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.01em;
          color: #171717;
        }
        .timer {
          padding: 0px 16px;

          a {
            gap: 12px;
            width: 300px;
            height: 56px;
            h6 {
              font-family: "General sans";
              font-size: 16px;
            }
          }
        }
        .checkback_info {
          margin-top: 45px;

          h6 {
            font-family: "General Sans light";
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.1em;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .contacts {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 16px;
    gap: 16px;

    position: absolute;
    width: 116px;
    left: 0px;
    bottom: 15%;
    background: #171717;
    border-radius: 0px 92px 92px 0px;

    .contact_wrapper {
      width: 100%;
      .contact_wrapper_inner {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
  .contacts2 {
    display: none;
  }

  @media only screen and (max-width: 920px) {
    .contacts {
      display: none;

      .contact_wrapper {
        width: 100%;
        .contact_wrapper_inner {
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
    }
    .contacts2 {
      margin-top: 80px;
      margin-bottom: 40px;
      display: flex;
      width: 106px;
      padding: 16px 16px;
      border-radius: 0px 92px 92px 0px;
      background: #171717;
      .contact_wrapper {
        width: 90%;
        margin: 0px 6px;
        .contact_wrapper_inner {
          width: 100%;
          margin-right: 0px 16px;
          img {
            width: 100%;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 540px) {
    .contacts {
      display: none;

      .contact_wrapper {
        width: 100%;
        .contact_wrapper_inner {
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
    }
    .contacts2 {
      margin-top: 80px;
      margin-bottom: 40px;
      display: flex;
      width: 106px;
      padding: 16px 16px;
      border-radius: 0px 92px 92px 0px;
      background: #171717;
      .contact_wrapper {
        width: 90%;
        margin: 0px 6px;
        .contact_wrapper_inner {
          width: 100%;
          margin-right: 0px 16px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 920px) {
  .homepage {
    height: 100%;
  }
}
@media only screen and (max-width: 540px) {
  .homepage {
    height: 100%;
  }
}

.page-layout {
  position: relative;

  .menu-toggle-button {
    z-index: 10;
    display: flex;
    align-items: center;
    vertical-align: middle;

    div {
      align-items: center;
      display: flex;
    }

    svg {
      display: flex;
      align-items: center;
      vertical-align: middle;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: #171717;
    }

    .button_wrapper {
      cursor: pointer;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 70px;
      background-color: #ffffff;
      border: 0.5px solid rgba(23, 23, 23, 0.2);
      box-shadow: 0px 12px 24px -4px rgba(16, 24, 40, 0.03);
      margin-left: 28px;
    }
  }

  .side-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 25%;
    height: 100%;
    overflow: scroll;
    background-color: #171717;
    transform: translateX(90%);
    transition: transform 0.5s ease-in-out;

    &.open {
      transform: translateX(0);
    }
    .side_menu_footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #171717;
      backdrop-filter: blur(12.5px);
      div {
        display: flex;
        align-items: center;
        color: #fcfcfc;
        margin: 18px 48px;
        p {
          margin-left: 8px;
        }
      }
    }
    .side_menu_cover {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-top: 40px;
      align-self: center;
      color: #fcfcfce5;
      .side_menu_cover_img_wrapper {
        width: 70%;
        height: 222px;

        img {
          width: 100%;
        }
      }
      div:nth-child(2) {
        margin: 0px 48px;
        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.008em;
          color: rgba(252, 252, 252, 0.9);
        }
        span {
          display: flex;

          p {
            margin-right: 5px;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.008em;
            color: #fcfcfc;
            margin-bottom: 2px;
          }
          p:nth-child(2) {
            color: #fbaa47;
          }
        }
      }
    }
  }

  .main-content {
    margin-right: 0;
    top: 0;
    margin: 0px 48px;
    transition: margin-right 0.5s ease-in-out;

    &.open {
      margin-right: 25%;
    }
    .main_content_header {
      display: flex;
      margin-bottom: 41px;
      margin-top: 18px;
      justify-content: space-between;

      .main_content_logo {
        display: flex;
        align-items: center;

        .form__inner__input {
          display: flex;
          flex-direction: column;
          // padding: 15px 0px;
          select {
            // width: 100%;
            font-size: 16px;
            border: 1px solid transparent;
            border-radius: 6px;
            padding: 13px 12px;
            background-color: white;
            color: black;
            -webkit-appearance: none;
          }

          select:focus {
            outline: none;
            background-color: #fbfffe;
          }

          label {
            color: #3c3e3f;
            display: flex;
            // width: 100%;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 0.1rem;
          }
        }
      }
      .main_content_logo_wrapper {
        width: 35%;
        margin-right: 8px;
        // height: 36px;
        .main_content_logo_wrapper_inner {
          width: 100%;
          display: flex;
          border-radius: 70px;
          align-items: center;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .main_content_header_inner {
    display: flex;
    justify-content: space-between;

    div:nth-child(1) {
      width: 60%;
    }

    .main_content_header_partyImages {
      display: flex;
      align-items: center;
      width: 35%;
      justify-content: flex-end;
      margin-right: 48px;
      .main_content_header_inner_img_cover {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .main_content_header_inner_img_wrapper {
          width: 50%;
          img {
            width: 100%;
          }
        }
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.03em;
          color: #171717 !important;
          text-decoration: none !important;
          opacity: 0.9;
        }
      }
      .main_content_header_inner_img_cover:nth-child(2) {
        margin-bottom: 88px;
      }
    }
    h1 {
      font-weight: 500;
      font-size: 72px;
      line-height: 92px;
      letter-spacing: -0.04em;
      color: #171717;
    }

    div:nth-child(2) {
      display: flex;
      margin-top: 16px;
      p {
        margin-right: 16px;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.008em;
        color: #171717;
        opacity: 0.95;
      }
      p:nth-child(1) {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.008em;
        color: #171717;
        opacity: 0.95;
      }
      p:nth-child(2) {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.008em;
        text-decoration-line: line-through;
        color: #d91a46;
        opacity: 0.95;
      }
    }
  }
  .polling_units_table {
    margin: 48px 48px 0px 0px;
    background: #ffffff;
    border: 0.5px solid rgba(23, 23, 23, 0.1);
    box-shadow: 0px 2px 16px rgba(18, 33, 46, 0.04);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    .pollen_units_table_input {
      display: flex;
      align-items: center;
      margin: 24px;
      border: 1px solid #a2a2a2;
      border-radius: 70px;
      padding: 1px;
      width: 20%;
      input {
        font-size: 16px;
        width: 100%;
        border: 1px solid transparent;
        border-radius: 70px;
        padding: 13px 12px;
      }

      input:focus {
        outline: none;
        border: 1px solid transparent;
        background-color: #ffffff;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        margin-left: 13px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: 0.03em;
        color: #171717;
      }
      svg {
        margin-left: 15px;
      }
    }
    .pollen_units_table_input:nth-child(2) {
      width: 20%;
    }
  }
  .polling_units_table_inner {
    margin: 0px 48px 0px 0px;
    background: #fcfcfc;
    border: 0.5px solid rgba(23, 23, 23, 0.1);
    display: flex;
    justify-content: space-between;
    div {
      padding: 24px;
      margin: 0px;

      p {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #171717;
      }
    }
    div:nth-child(1) {
      flex: 1;
      max-width: 40%;
    }
    div:nth-child(2) {
      flex: 1;
      max-width: 15%;
    }
    div:nth-child(3) {
      flex: 1;
      max-width: 15%;
    }
    div:nth-child(4) {
      flex: 1;
      max-width: 15%;
    }
    div:nth-child(5) {
      flex: 1;
      max-width: 7%;
      text-align: center;
    }
  }
}

.election_table {
  background-color: #f3f4f6;
  display: flex;
  flex-direction: column;

  .side-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #21944b;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 100000000000000;
    opacity: 1;
    transition: opacity 0.5s;
  }

  .side-button:hover {
    opacity: 0.7;
  }
  @media only screen and (max-width: 540px) {
    .side-button {
      position: fixed;
      bottom: 20px;
      left: 10px;
      background-color: rgb(33, 148, 75);
      color: white;
      padding: 10px 15px;
      font-size: 12px;
      border-radius: 5px;
      cursor: pointer;
      z-index: 100000000000000;
      opacity: 1;
      transition: opacity 0.5s;
    }
  }
  .banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: black;
    color: white;
    z-index: 1000000000000;
    padding: 20px;
    text-align: center;
  }

  @media only screen and (max-width: 540px) {
    .banner {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.998);
      color: white;
      font-size: 12px;
      padding: 5px 5px;
      z-index: 1000000000000;
      text-align: center;
      margin-bottom: 22px;
    }
  }

  .header {
    display: flex;
    margin: 18px 32px;
    margin-top: 100px;
    justify-content: space-between;
    .homepage_header_inner {
      flex: 1;
      max-width: 70%;
      display: flex;
      align-items: center;
    }
    .homepage_header_wrapper {
      width: 30%;
      margin-right: 12px;

      .homepage_header_wrapper_inner {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }

    @media only screen and (max-width: 540px) {
      .homepage_header_inner {
        flex: 1;
        margin-bottom: 16px;
      }
      .homepage_header_wrapper {
        width: 90%;
      }
    }
    p {
      font-family: "General Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      color: #171717;
    }
    a {
      color: #ffffff;
      text-decoration: none;

      .contact {
        display: flex;
        align-items: center;
        padding: 10px 16px;
        gap: 8px;
        background: #171717;
        border-radius: 92px;

        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: right;
          color: #ffffff;
        }
      }
    }
  }

  @media only screen and (max-width: 920px) {
    .header {
      margin: 0px 0px;
      margin-top: 90px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .body {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin: 30px 80px;
    border: 0.1px solid #edeef0;
    box-shadow: 0px 2px 14px rgba(18, 33, 46, 0.02);
    border-radius: 16px;

    .body_head {
      margin: 40px auto;

      h2 {
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 12px;
        font-family: "General Sans light";
        letter-spacing: -0.02em;
        color: #171717;
      }
      @media only screen and (max-width: 540px) {
        h2 {
          font-size: 16px;
        }
      }
      p {
        font-family: "General Sans light";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        text-align: left;
        color: #616262;
        margin-bottom: 6px;
      }
      @media only screen and (max-width: 540px) {
        p {
          font-size: 12px;
        }
      }
    }
    .body_head:nth-child(3) {
      margin-bottom: 0px;
      margin-top: 0px;
    }
    .body_inner {
      margin: auto;
      display: flex;
      justify-content: space-evenly;
      .body_inner_image {
        width: 20%;
        img {
          width: 100%;
        }
      }
      @media only screen and (max-width: 920px) {
        .body_inner_image {
          display: none;
        }
      }
      .body_inner_chart {
        width: 584px;
        height: 48px;
        display: flex;
        background: #ffffff;
        border: 1px solid #f2f3f5;
        position: relative;
        border-radius: 40px;
        margin-bottom: 15px;

        .body_inner_chart_guage {
          position: absolute;
          // top: 0;
          // bottom: 0;
          // left: 0;
          // width: 70%;
          height: 100%;
          // background-color: #1d91e93a;
          border-radius: 40px;
          // margin: 2px 6px;
        }

        .body_inner_chart_guage_text {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          padding: 5px 12px;
          .body_inner_chart_guage_text_wrapper {
            width: 15%;
            display: flex;
            align-items: center;
            z-index: 100000;
            img {
              width: 100%;
            }
          }
          p {
            margin-left: 12px;
            z-index: 10000;
          }
        }
        @media only screen and (max-width: 540px) {
          p {
            font-size: 12px;
          }
        }
      }

      @media only screen and (max-width: 920px) {
        .body_inner_chart {
          width: 100%;
        }
      }
    }
    .body_table {
      margin: 48px 100px;

      .body_head {
        margin: 10px 0px;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
      }

      .bottom_nav {
        color: black;
        text-align: center;
        margin-top: 30px;
        width: 100%;

        p {
          font-style: italic;
          text-align: left;
        }
      }
      @media only screen and (max-width: 920px) {
        .body_head {
          margin: 10px 18px;
        }
        .bottom_nav {
          color: black;
          text-align: center;
          margin-top: 20px;
          font-size: 12px;
          padding: 5px 12px;
        }
      }

      .body_table_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 24px 24px 0px;

        // width: 1120px;
        height: 72px;

        /* Base/White */

        background: #ffffff;
        border: 1px solid rgba(23, 23, 23, 0.08);
        border-radius: 12px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        a {
          text-decoration: none;
          color: #171717;
          margin-right: 32px;
          font-weight: 500;
          font-size: 16px;
          font-family: "General sans light";
          line-height: 24px;
          padding-bottom: 20px;
          /* identical to box height, or 150% */

          display: flex;
          align-items: center;
          letter-spacing: -0.01em;
          text-transform: capitalize;

          /* General/Black */

          color: #171717;
        }
        @media only screen and (max-width: 540px) {
          a {
            font-size: 12px;
            width: 100%;
          }
        }
        .active {
          border-bottom: 2px solid black;
        }
      }
      @media only screen and (max-width: 920px) {
        .body_table_header {
          width: 100%;
          display: flex;
          justify-content: space-between;

          a {
            flex-direction: column;
            display: flex;
          }
        }
      }
    }
    @media only screen and (max-width: 540px) {
      .body_table {
        margin: 0;
      }
    }
  }
  @media only screen and (max-width: 920px) {
    .body {
      margin: 20px 18px;
    }
  }
  @media only screen and (max-width: 540px) {
    .body {
      margin: 20px 12px;
    }
  }
}
