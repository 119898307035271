@font-face {
  font-family: 'General sans light';
  src: url('./utils/FontshareKit-2303001367/General Sans/Fonts/WEB/fonts/GeneralSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'General sans';
  src: url('./utils/FontshareKit-2303001367/General Sans/Fonts/WEB/fonts/GeneralSans-Regular.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'General sans', 'General sans light', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'General sans', 'General sans light', sans-serif;
}